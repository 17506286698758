window.dataLayer = window.dataLayer || [];

const trackOptimizelyEvent = (eventName) => {
  if (
    window.optimizely?.initialized &&
    [
      'Clicked Nav Give Button - Scrolled to Donate Form',
      'Clicked Nav Give Button - Opened Donate Form Modal',
      'Clicked Nav Give Button - Redirected to Donate Page',
    ].includes(eventName)
  ) {
    window.optimizely.push({
      eventName: eventName,
      tags: {},
      type: 'event',
    });
  }
};

window.track_event = (eventName, properties = {}) => {
  window.dataLayer.push({
    event: eventName,
    ...properties,
  });

  trackOptimizelyEvent(eventName);
};

const dataUsedOnGTMInit = () => {
  const optimizelyRedirectInfo = window.optimizely
    ?.get?.('state')
    ?.getRedirectInfo?.();
  const preRedirectReferrerOrCurrentReferrer = optimizelyRedirectInfo
    ? optimizelyRedirectInfo.referrer
    : document.referrer;

  return {
    donation_interface: !!window.StripeTerminal ? 'lab_kiosk' : null,
    gpc_enabled: !!navigator.globalPrivacyControl,
    real_referrer: preRedirectReferrerOrCurrentReferrer,
    redirected_by_optimizely: !!optimizelyRedirectInfo,
    user_id: window.userId || undefined,
  };
};

window.dataLayer.push(dataUsedOnGTMInit());
window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });

const scriptTarget = document.getElementsByTagName('script')[0];
const script = document.createElement('script');
script.async = true;
script.src = window.gtmScriptUrl;
scriptTarget.parentNode.insertBefore(script, scriptTarget);
